<template>
  <div style="min-width: 1360px">
    <Header/>
    <new-home-header title="MES制造执行系统" @btnClick="btnClick" :content="content" :price="price"
                     :imgSrc="`${require('@/assets/img/payImages/digIntFactory.png')}`" imgStyle="height:325px"/>
    <div class="page-content">
      <div class="card-title">MES制造执行系统</div>
      <div class="card-tips">{{ tips }}</div>
      <div class="erp-container">
        <el-image style="width: 100%; height: 520px" :src="`${require('@/assets/img/home/top2_1.png')}`" fit="fill"/>
      </div>
    </div>
    <bannerList :title="title" :titleStyle="{fontSize:'24px',color:'#fff',fontWeight:400}"
                :borderStyle="{backgroundColor:'#182041'}" :dataList="schemeList"
                :cardStyle="{backgroundColor:'#26315D',color:'#fff',height:'340px',width:'230px'}"
                :cardTitleStyle="{color:'#fff',fontSize:'18px',textAlign:'center'}"
                :cardContentStyle="{textAlign:'left'}"/>
    <div class="page-content">
      <div class="title-box">
        <span>解决智能制造三大难题，创造核心价值</span>
      </div>
      <div class="erp-container">
        <div class="item-list-top">
          <div class="item-top">
            <img :src="`${require('@/assets/img/payImages/shortenCycle.png')}`" class="item-img">
            <div class="right-str">
              <div class="div1">缩短周期</div>
              <div class="div2">制造周期缩短&nbsp;<span class="num1">45%</span></div>
              <div class="div2">在制品WIP减少&nbsp;<span class="num1">30%</span></div>
            </div>
          </div>
          <div class="item-top">
            <img :src="`${require('@/assets/img/payImages/costReduction.png')}`" class="item-img">
            <div class="right-str">
              <div class="div1">降本增效</div>
              <div class="div2">效率提升&nbsp;<span class="num1">26%</span></div>
              <div class="div2">成本降低&nbsp;<span class="num1">27%</span></div>
            </div>
          </div>
          <div class="item-top">
            <img :src="`${require('@/assets/img/payImages/improvingQuality.png')}`" class="item-img">
            <div class="right-str">
              <div class="div1">提升质量</div>
              <div class="div2">质量过失减少<span class="num1">70%</span></div>
              <div class="div2">缺点数降低<span class="num1">25%</span></div>
            </div>
          </div>
        </div>
        <div class="item-list-foot">
          <div class="item-foot" v-for="item in fourList" :key="item.title">
            <div class="title">
              <span>{{ item.title }}</span>
            </div>
            <div class="tips">
              <span>{{ item.tips }}</span>
            </div>
            <div class="con-list" v-for="row in item.conList" :key="row">
              <div class="statubox">
                <i class="el-icon-check statuicon"></i>
              </div>
              <span class="str">&nbsp;{{ row }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'MES制造执行系统'"/>
    <new-cloude-footer/>
  </div>
</template>
<script>
import bannerList from "@/components/assembly/bannerList.vue";
import newHomeHeader from "@/components/assembly/newHomeHeander";
import Header from "@/views/home/newCloudeHeader.vue";
import applyTrial from "@/components/assembly/applyTrial";
import newCloudeFooter from "@/views/home/newCloudeFooter.vue";

export default {
  name: "factory",
  components: {newHomeHeader, bannerList, applyTrial, Header, newCloudeFooter},
  data() {
    return {
      schemeList: [
        {
          title: '派工报工<br/>人员技能等级',
          image: `${require('@/assets/img/home/top3_ico1.png')}`,
          content: '通过生产任务中心，弹性调整派工量与生产任务到指定产线。解决员工派工报工受限。提升人员工作效率。'
        },
        {
          title: '机台稼动参数<br/>点检保养<br/>',
          image: `${require('@/assets/img/home/top3_ico2.png')}`,
          content: '整合机台信息，实时掌握机台稼动参数，设备运行状态、保养履历，减少意外停机与故障，提升设备生产效率。'
        },
        {
          title: '物料流转<br/>库存周转',
          image: `${require('@/assets/img/home/top3_ico3.png')}`,
          content: '电子看板整合订单进度工单生产状况、缺料资讯、机台状态等信息最大程度地减少停机时间，高效配置生产资源。'
        },
        {
          title: '弹性生产<br/>流程追溯',
          image: `${require('@/assets/img/home/top3_ico4.png')}`,
          content: '透过产品批号回溯原材料、工序、机台、人员等信息，定位异常原因，同时满足弹性生产需求，随时应对客户验厂。'
        },
        {
          title: '品质检测<br/>流程追溯',
          image: `${require('@/assets/img/home/top3_ico5.png')}`,
          content: '透过产品批号回溯原材料、工序、机台、人员等信息，定位异常原因，同时满足弹性生产需求，随时应对客户验厂。'
        },
      ],
      fourList: [
        {
          title: '加速反应',
          tips: '提高工艺派工及调度的能力',
          conList: ['产品工时/流程标准化', '设备状态管理', '设备负荷管理', '工艺派工管理', '派工调度管理']
        },
        {
          title: '减少无效作业',
          tips: '车间现场透明化能力',
          conList: ['任务透明化', '人员透明化', '设备透明化', '进度透明化', '质量透明化']
        },
        {
          title: '减少准备',
          tips: '提高效率化开工能力',
          conList: ['E-SOP管理', '设备参数管理', '模治具管理', '料齐套管理', '任务进站管理']
        },
        {
          title: '减少等待',
          tips: '提高效率化的完工转移能力',
          conList: ['IPQC检验管理', '任务出站管理', '流程指引管理']
        },
      ],
      title: '借助基于物联网的解决方案让企业全面升级。实现集成化车间管理，提升现场生产效率',
      tips: '监控和管理制造过程，实现生产现场的透明化，提供实时、准确的生产数据，支持生产计划的执行和调度。优化和整合生产资源，包括设备、工具、人员、物料等，提高资源的利用率和效率，降低生产成本。',
      content: '监控和管理制造过程，实现生产现场的透明化，提供实时、准确的生产数据，支持生产计划的执行和调度。优化和整合生产资源，包括设备、工具、人员、物料等，提高资源的利用率和效率，降低生产成本。',
      price:'市场价格：650000元',
    }
  },
  methods: {
    btnClick() {
      this.$refs.dialog.show = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.page-content {
  padding: 60px 0;
}

.card-title {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  text-align: center;
  padding-bottom: 30px;
}

.card-tips {
  font-size: 16px;
  width: 750px;
  margin: auto;
  text-align: center;
  line-height: 1.4;
}

.erp-container {
  width: 1200px;
  margin: auto;
  padding-top: 40px;

  .left {
    width: 800px;
  }
}

.title-box {
  font-size: 24px;
  font-family: 'SourceHanSansCN';
  color: #333333;
  display: flex;
  justify-content: center;
}

.item-list-foot {
  margin-top: 40px;
  display: flex;

  .item-foot {
    margin-right: 120px;
  }

  .title {
    font-size: 24px;
    font-family: 'SourceHanSansCN';
    color: rgba(51, 51, 51, 1);
  }

  .tips {
    font-size: 16px;
    font-family: 'SourceHanSansCN';
    color: rgba(102, 102, 102, 1);
    line-height: 36px;
  }

  .con-list {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .str {
      color: rgba(51, 51, 51, 1);
      font-size: 16px;
      font-family: 'SourceHanSansCN';
    }
  }
}

.item-list-top {
  display: flex;
  justify-content: space-between;

  .item-top {
    width: 330px;
    height: 80px;
    padding: 30px;
    display: flex;
    background: #F6F8FF;

    .item-img {
      width: 80px;
      height: 80px;
      margin-right: 30px;
    }

    .right-str {
      .div1 {
        color: rgba(25, 53, 222, 1);
        font-size: 18px;
        font-family: 'SourceHanSansCN';
      }

      .div2 {
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
        font-family: 'SourceHanSansCN';

        .num1 {
          font-size: 24px;
          color: rgba(254, 39, 59, 1);
          font-family: 'SourceHanSansCN';
        }
      }
    }
  }
}

.statubox {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1935DE;
}

.statuicon {
  color: #fff;
  font-size: 12px;
}
</style>
